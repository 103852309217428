// -- The HTML Serializer
// This function will be used to modify the way that a Rich Text or Title field is rendered.

import React from 'react';
import { asLink } from '@prismicio/helpers';
import InternalLink from '@components/Link/Link';
import PrismicLink from '@components/PrismicLink/PrismicLink';
import CookieConsent from '@components/CookieConsent/CookieConsent';
import linkResolver from './linkResolver';
import handleEditCookieConsent from '../../utils/handleEditCookieConsent';

import IconTickGreen from '@images/icons/IconTickGreen/IconTickGreen';
import IconXRed from '@images/icons/IconXRed/IconXRed';
import ConditionalWrapper from '../../components/ConditionalWrapper/ConditionalWrapper';

const htmlSerializer = {
  hyperlink: ({ node, children, key }) => {
    const url = asLink(node.data, linkResolver);

    return (
      <PrismicLink key={key} to={url} targetBlank={node.data.target === '_blank'}>
        {children}
      </PrismicLink>
    );
  },
  label: ({ node, key, children }) => {
    if (node.data.label === 'blockquote') {
      return <blockquote key={key}>{children}</blockquote>;
    }
    if (node.data.label === 'icon_cross') {
      return (
        <span key={key}>
          <IconXRed />
        </span>
      );
    }

    if (node.data.label === 'icon_check') {
      return (
        <span key={key}>
          <IconTickGreen />
        </span>
      );
    }
  },
  // We need to wrap the embed in one more div in order to render it with the correct aspect ratio
  embed: ({ node, key }) => {
    const { type, html, embed_url, provider_name } = node?.oembed;

    let finalHtml = html;

    if ('YouTube' === provider_name) {
      finalHtml = html?.replace('https://www.youtube.com', 'https://www.youtube-nocookie.com');
    }

    return (
      <ConditionalWrapper
        condition={provider_name !== 'YouTube'}
        wrapper={children => (
          <CookieConsent
            category="statistics"
            fallback={
              <div className="embed-cookie-fallback">
                <p>
                  Embedded content not shown due to cookie preferences.{' '}
                  <InternalLink to="/privacy-policy/" onClick={handleEditCookieConsent}>
                    adjust your preferences
                  </InternalLink>
                </p>
              </div>
            }
          >
            {children}
          </CookieConsent>
        )}
      >
        <div
          // ClassName is used to initiate twitter script tag -> https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-twitter/src/gatsby-browser.js
          className={provider_name === 'Twitter' ? 'twitter-tweet' : null}
          key={key}
          data-oembed={embed_url}
          data-oembed-type={type}
          data-oembed-provider={provider_name}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: finalHtml,
            }}
          />
        </div>
      </ConditionalWrapper>
    );
  },
};

export default htmlSerializer;
