import React from 'react';
import { fireAnalyticsEvent } from '../../analytics';

// External link that opens in a new tab/window, ensuring that the
// opened page doesn't have access to the current page.
//
// See: https://mathiasbynens.github.io/rel-noopener/
//
// Usage:
//
// <ExternalLink href="url">text</ExternalLink>
//   => <a href="url" target="_blank" rel="noopener">text</a>
//
// <ExternalLink href="url" noFollow>text</ExternalLink>
//   => <a href="url" target="_blank" rel="noopener nofollow">text</a>
const ExternalLink = props => {
  const { children, targetBlank, noFollow, href, analyticsEvent, analyticsProperties, ...rest } =
    props;

  let rel = null;

  // Support either target blank, nofollow, or both.
  if (targetBlank && noFollow) {
    rel = 'noopener nofollow';
  } else if (targetBlank) {
    rel = 'noopener';
  } else if (noFollow) {
    rel = 'nofollow';
  }

  // Add convenience around target and rel handling, but allow overriding them
  // in the rest props if needed.
  const linkProps = {
    target: targetBlank ? '_blank' : null,
    rel,
  };

  return (
    <a
      {...linkProps}
      {...rest}
      href={href}
      onClick={e => {
        if (analyticsEvent) {
          // Hire an analytics event if the current URL matches the URL set by the AB experiment cookie
          // const url = window.location.pathname.replace(/\//, '').replace(/\/+$/, '');
          // let cookie = Cookies.get('ab_experiment')?.split('.')[0];
          // if (url === cookie) {
          // }

          fireAnalyticsEvent(analyticsEvent, analyticsProperties);

          // Allow event to be sent before the page is unloaded
          if (!targetBlank) {
            setTimeout(function () {
              window.location.href = href;
            }, 250);
            e.preventDefault();
          }
        }

        return true;
      }}
    >
      {children}
    </a>
  );
};

ExternalLink.defaultProps = {
  targetBlank: true,
  noFollow: false,
};

export default ExternalLink;
