import Cookies from 'js-cookie';

const COOKIE_NAME = 'ab_experiment';

export const fireAnalyticsEvent = (eventName, properties = {}) => {
  // Quora ads
  if (window.qp && eventName === 'custom-sharetribe-account-started') {
    const urlParams = new URLSearchParams(window?.location?.search);
    const eventId = urlParams?.get('qclid');
    if (eventId) {
      window.qp('track', 'Clicked Start Trial', { event_id: eventId });
    } else {
      window.qp('track', 'Clicked Start Trial');
    }
  }

  // Google ads
  if (window.gtag && eventName === 'custom-sharetribe-account-started') {
    window.gtag('event', 'conversion', {
      send_to: `AW-941416228/juewCKLuiGAQpL7zwAM`,
    });
  }

  if (window.plausible) {
    const cookie = Cookies.get(COOKIE_NAME);
    if (cookie) {
      const isAbTest = eventName === 'custom-sharetribe-account-started';
      if (isAbTest) {
        window.plausible(eventName, { props: { 'AB test': cookie, ...properties } });
      } else {
        window.plausible(eventName, { props: properties });
      }
    } else {
      window.plausible(eventName, { props: properties });
    }
  }
};
