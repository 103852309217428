import React from 'react';
import linkResolver from '@prismic/utils/linkResolver';

// import HowToBuildPage from '@templates/howToBuild-template';
// import HowToDevelopPage from '@templates/howToDevelop-template';
// import ShowcasePost from '@templates/ShowcasePost/ShowcasePost';
// import BlogPost from '@templates/blogPost';
// import AcademyPost from '@templates/academyPost';
// import LandingPage from '@templates/landing';
// import AcademyLanding from '@templates/academyLanding';
// import Author from '@templates/author';
// import Category from '@templates/category';
// import PodcastEpisode from '@templates/podcastEpisode';
// import MarketplaceTypePage from '@templates/marketplaceTypePage';
// import FeatureHighlightPage from '@templates/featureHighlightPage';
// import LegalPage from '@templates/LegalPage/LegalPage';

const HowToBuildPage = React.lazy(() => import('../../templates/howToBuild-template'));
const HowToDevelopPage = React.lazy(() => import('../../templates/howToDevelop-template'));
const ShowcasePost = React.lazy(() => import('../../templates/ShowcasePost/ShowcasePost'));
const BlogPost = React.lazy(() => import('../../templates/blogPost'));
const AcademyPost = React.lazy(() => import('../../templates/academyPost'));
const LandingPage = React.lazy(() => import('../../templates/landing'));
const AcademyLanding = React.lazy(() => import('../../templates/academyLanding'));
const Author = React.lazy(() => import('../../templates/author'));
const Category = React.lazy(() => import('../../templates/category'));
const PodcastEpisode = React.lazy(() => import('../../templates/podcastEpisode'));
const MarketplaceTypePage = React.lazy(() => import('../../templates/marketplaceTypePage'));
const FeatureHighlightPage = React.lazy(() => import('../../templates/featureHighlightPage'));
const LegalPage = React.lazy(() => import('../../templates/LegalPage/LegalPage'));
const JobPosition = React.lazy(() => import('../../templates/JobPosition/JobPosition'));

export const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: {
      how_to_build_page: HowToBuildPage,
      how_to_develop_page: HowToDevelopPage,
      showcase_post: ShowcasePost,
      blog_post: BlogPost,
      academy_post: AcademyPost,
      landing_page: LandingPage,
      academy_landing: AcademyLanding,
      person: Author,
      category: Category,
      podcast_episode: PodcastEpisode,
      marketplace_type_page: MarketplaceTypePage,
      feature_highlight_page: FeatureHighlightPage,
      legal_page: LegalPage,
      job_position: JobPosition,
    },
  },
];
