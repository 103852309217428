import React from 'react';

const IconXRed = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.133883.3661165L5.669 3.901 9.204951.3661165c.488156-.4881553 1.279612-.4881553 1.767767 0 .488156.4881554.488156 1.2796116 0 1.767767L7.436 5.669l3.536718 3.5359513c.488156.4881554.488156 1.2796116 0 1.7677669-.488155.4881554-1.279611.4881554-1.767767 0L5.669 7.436l-3.535117 3.5367182c-.488155.4881554-1.279611.4881554-1.767766 0-.488156-.4881553-.488156-1.2796115 0-1.7677669L3.901 5.669.366117 2.1338835c-.488156-.4881554-.488156-1.2796116 0-1.767767.488155-.4881553 1.279611-.4881553 1.767766 0z"
      fill="red"
      fillRule="evenodd"
    />
  </svg>
);

export default IconXRed;
