const handleEditCookieConsent = e => {
  e.preventDefault();
  if (window?.Cookiebot?.renew) {
    window.Cookiebot.renew();
  } else {
    throw new Error('No Cookiebot enabled');
  }
};

export default handleEditCookieConsent;
