import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { fireAnalyticsEvent } from '../../analytics';
import { useLocation } from '@reach/router';

const noop = () => undefined;

// Wrapper component for Gatsby's Link with added support for relative
// hash links.
//
// If the current page path is "/some/path/", Gatsby's Link:
//
// <Link to="#hash">text</Link> => <a href="/#hash">text</a>
//
// while this Link:
//
//   <Link to="#hash">text</Link> => <a href="/some/path/#hash">text</a>
const Link = props => {
  const { to = '', onClick = noop, analyticsEvent, analyticsProperties, ...rest } = props;

  const location = useLocation();
  const pathname = location.pathname;

  const isHashUrl = to?.startsWith('#');
  const linkProps = {
    to: isHashUrl ? `${pathname}${to}` : to,
    onClick: e => {
      if (analyticsEvent) {
        fireAnalyticsEvent(analyticsEvent, analyticsProperties);
      }
      return onClick(e);
    },
    ...rest,
  };
  // if (!isHashUrl && !to) {
  //    console.warn(`Link "to" missing`);
  //  } else if (!isHashUrl && !to.endsWith('/')) {
  //    console.warn(
  //      `Link "to" doesn't end with "/": ${to}. This will generate unnecessary redirects in Vercel.`
  //    );
  //  }

  return <GatsbyLink {...linkProps} />;
};

export default Link;
