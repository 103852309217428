import React from 'react';

const IconTickGreen = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.512563.581108L3.922801 8.514525 2.335572 6.927296c-.534205-.534205-1.401033-.534205-1.935238 0-.534066.538164-.534066 1.403916.001724 1.936958l2.735215 2.735219c.25715.25715.605033.400864.967619.400864.442382 0 .860859-.213698 1.118837-.579729l6.526638-9.263616c.435016-.61969.287125-1.473354-.331821-1.907902-.618121-.434409-1.47107-.286159-1.905983.332018zm.817412 1.218918l.543998-.775669-.543998.775669z"
      fill="#2ECC71"
      fillRule="nonzero"
    />
  </svg>
);

export default IconTickGreen;
