import React from 'react';
import InternalLink from '@components/Link/Link';
import ExternalLink from '@components/ExternalLink/ExternalLink';
import { fireAnalyticsEvent } from '../../analytics';

const SUBPATHS_OUTSIDE_GATSBY_MARKETING_ARCHITECTURE = [
  'https://www.sharetribe.com/community',
  'https://www.sharetribe.com/docs',
  'https://www.sharetribe.com/api-reference',
  'https://www.sharetribe.com/help/en',
  'https://www.sharetribe.com/dev-slack',
  'https://console.sharetribe.com/',
];

// This component takes a Prismic URL string, checks if it's internal or external and decides which href component to render
// Internal pages which are in Prismic will already have a relative path (automatically generated through linkResolver), but some pages are not created by Prismic, therefore we need to link them using the absolute URL.
// For example, we'd want these results:
// /balanced/article-uid/ -> /balanced/article-uid/ (Internal page created on Prismic)
// https://www.sharetribe.com/academy/articles/ -> /academy/articles/ (Internal page not created on Prismic)
// https://www.google.com/ -> https://www.google.com/ (External URL)
// mailto:support@sharetribe.com -> mailto:support@sharetribe.com (Contact link)
const PrismicLink = props => {
  const { to, children, targetBlank, ...rest } = props;

  // If there are no children, don't render anything
  if (!children) {
    return null;
  }

  // If there is no link, render an empty link
  if (!to) {
    return <a href="">{children}</a>;
  }

  const internalFullHref =
    /^\/(?!\/)/.test(to) ||
    (to.includes('https://www.sharetribe.com') &&
      !new RegExp(SUBPATHS_OUTSIDE_GATSBY_MARKETING_ARCHITECTURE.join('|')).test(to));

  let finalHref = to;

  if (internalFullHref) {
    finalHref = to.replace('https://www.sharetribe.com', '');
  }

  // if the link has a hash on the same page, leave only the hash
  if (typeof window !== 'undefined') {
    if (
      !internalFullHref &&
      finalHref.includes(window.location.pathname) &&
      window.location.pathname !== '/'
    ) {
      finalHref = finalHref.replace(window.location.pathname, '');
    }
  }

  const isHashUrl = finalHref.startsWith('#');
  const isExternal = finalHref.indexOf(`http`) >= 0;
  const isContactTo = finalHref.indexOf(`mailto:`) === 0 || finalHref.indexOf(`tel:`) === 0;

  // render a normal link for the hash
  if (isHashUrl) {
    return (
      <a
        onClick={() => {
          const { analyticsEvent, analyticsProperties } = rest;

          if (analyticsEvent) {
            fireAnalyticsEvent(analyticsEvent, analyticsProperties);
          }
        }}
        {...rest}
        href={finalHref}
        aria-label="Anchor link"
      >
        {children}
      </a>
    );
  }

  // Don't open in a new tab (unless explicitly set in Prismic) if part of the sharetribe domain, but technically still an external link
  const openNewTab =
    !new RegExp(SUBPATHS_OUTSIDE_GATSBY_MARKETING_ARCHITECTURE.join('|')).test(finalHref) ||
    targetBlank === '_blank';

  return isExternal ? (
    <ExternalLink {...rest} href={finalHref} targetBlank={openNewTab}>
      {children}
    </ExternalLink>
  ) : isContactTo ? (
    <a {...rest} href={finalHref}>
      {children}
    </a>
  ) : (
    <InternalLink {...rest} to={finalHref}>
      {children}
    </InternalLink>
  );
};

export default PrismicLink;
